<template>
  <div>
    <vs-popup class="sm:popup-w-60" title="Setup Other COA" :active="isActive" v-on:update:active="emitModalIsActive">

      <div class="h-screen">
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
              <!--  Button Goes Here   -->
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 float-right" placeholder="Cari" v-model="search"/>
          </div>
        </div>

        <vs-table :data="data.items" stripe>
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">Kode</vs-th>
            <vs-th class="whitespace-no-wrap">Deskripsi</vs-th>
            <vs-th class="whitespace-no-wrap">Kode COA</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td> {{ item.key }} </vs-td>
              <vs-td> {{ item.alias }} </vs-td>
              <vs-td>
                <div class="w-64">
                  <vx-input-group>
                    <vs-input placeholder="Kode COA" :value="item.kode_coa" readonly @click="chooseCoa(item.uuid)"/>
                    <template slot="append">
                      <div class="append-text btn-addon">
                        <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="chooseCoa(item.uuid)"/>
                      </div>
                    </template>
                  </vx-input-group>
                </div>
              </vs-td>
            </vs-tr>

          </template>
        </vs-table>

        <div class="vx-row mt-6">
          <div class="vx-col w-full sm:w-6/12">
            <!--  Button Goes Here   -->
          </div>
          <div class="vx-col w-full sm:w-6/12">
            <div class="float-right">
              <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
              <vs-button :disabled="isLoading" @click="save">
                <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
                <span v-if="!isLoading">Simpan</span>
              </vs-button>
            </div>
          </div>
        </div>
      </div>

      <!--modal item coa-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih COA"
                :active="modalCoa.active"
                v-on:update:active="modalCoa.active = $event">
        <Coa :selectable="true" :externalFilter="filterCoa" @selected="onSelectedmodalCoa"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import SystemParameterRepository from '@/repositories/general/system-parameter-repository'
import Coa from '@/views/pages/master/coa/Coa'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import _ from 'lodash'

export default {
  name: 'SetupOtherCoa',
  props: ['isActive', 'item'],
  components: {
    ValidationErrors,
    Coa
  },
  watch: {
    isActive (active) {
      if (active && !this.isDataInited) {
        this.isDataInited = true
        this.getData()
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      isLoading: false,
      isLoadingGetData: false,
      errors: null,
      search: '',
      modalCoa: {
        rowUuid: null, // uuid from this.data.details[n].uuid
        active: false
      },
      data: {
        items: []
      }
    }
  },
  mounted () {

  },
  computed: {
    // rowsDetailRab () {
    //   const search = this.search
    //   const items = this.data.items
    //   if (search != null) {
    //     return _.filter(items, (item) => {
    //       return item.nama_item_rab.toLowerCase().includes(search)
    //     })
    //   }
    //   return items
    // },
    filterCoa () {
      return {
        tipe: 'DETAIL'
      }
    }
  },
  methods: {
    getData () {
      this.isLoadingGetData = true
      const params = { group_sub: 'POSTING' }

      SystemParameterRepository.getAll(params)
        .then(response => {
          this.data.items = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoadingGetData = false
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      SystemParameterRepository.updateAll(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    chooseCoa (rowUuid) {
      this.modalCoa.rowUuid = rowUuid
      this.modalCoa.active = true
    },

    onSelectedmodalCoa (data) {
      const uuid = this.modalCoa.rowUuid
      const index = _.findIndex(this.data.items, item => item.uuid === uuid)
      this.data.items[index].id_coa = data.id
      this.data.items[index].kode_coa = data.kode
      this.modalCoa.active = false
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
